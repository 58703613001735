import { template as template_083ae44a03324cb1b7bae0f9c44bade4 } from "@ember/template-compiler";
const WelcomeHeader = template_083ae44a03324cb1b7bae0f9c44bade4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
