import { template as template_f96125800a1d4ad8aed60420b5a5e92f } from "@ember/template-compiler";
const SidebarSectionMessage = template_f96125800a1d4ad8aed60420b5a5e92f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
