import { template as template_234c700907ac4a5db3788f646bc31e4d } from "@ember/template-compiler";
const FKLabel = template_234c700907ac4a5db3788f646bc31e4d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
